.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.contactsImg {
  width: 280px;
  pointer-events: none;
}

.contacts--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 6rem 0rem;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}

.contacts--container > h1 {
  font-family: var(--primaryFont);
  font-size: 3.5rem;
}

.contacts-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 0.6;
  box-sizing: border-box;
}

.personal-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 2.5rem;
}

.personal-details p {
  margin-left: 1.5rem;
  font-size: 18px;
  line-height: 110%;
  font-weight: 500;
  font-family: var(--primaryFont);
  width: 45%;
  word-break: break-word;
}

.socialmedia-icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

@media (min-width: 992px) and (max-width: 1380px) {
  .contactsImg {
    width: 240px;
  }
  .contacts--container {
    padding: 0rem 4rem;
    width: 90%;
  }
}

@media screen and (max-width: 992px) {
  .contactsImg {
    width: 240px;
    margin-top: 3rem;
  }
  .contacts--container {
    width: 100%;
    flex-direction: column;
    /* margin-top: 1rem; */
  }
  .contacts--container > h1 {
    font-size: 3rem;
    margin: 0 0 1.2rem 0;
  }
  .contacts-details {
    padding-left: 4rem;
  }
  .personal-details p {
    margin-left: 1rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .contacts--container h1 {
    font-size: 3rem;
  }
  .contacts--container {
    /* margin-top: rem; */
    align-items: center;
  }

  .contacts-details {
    padding-left: 0rem;
    padding: 1rem;
    align-items: center;
    margin-top: 2rem;
  }
  .socialmedia-icons {
    width: 100%;
    gap: 1.5rem;
    margin-top: 2rem;
  }
  .personal-details {
    margin-bottom: 1.8rem;
  }
  .personal-details p {
    margin-left: 1.2rem;
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .contacts-form {
    display: flex;
    flex: 1;
    width: 100%;
  }
  .contacts--container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 400px) {
  .contacts-details {
    padding-left: 0rem;
    padding: 0rem;
    align-items: center;
    margin-top: 2rem;
  }
  .submit-btn {
    align-self: center;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  .contacts-details {
    padding-left: 0rem;
    padding: 0rem;
  }
}

@keyframes spin {
  0% {
    opacity: 1;
    transform: translate(5px, -3px) rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes fly {
  10% {
    transform: rotate(0deg);
  }
  20% {
    transform: translateX(-10px);
  }
  70% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(80px);
  }
}
