.footer {
  font-family: "var(--primaryFont)", sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer p {
  font-weight: 500;
  font-family: var(--primaryFont);
  font-size: 18px;
  margin-top: 2rem;
}

.footer p span {
  font-size: 24px;
}
